// ** React Imports
import React, { useEffect, useRef, useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Snackbar from '@mui/material/Snackbar'

// ** 3rd Party Libraries
import { Terminal } from 'xterm'
import { Resizable } from 'react-resizable'
import 'xterm/css/xterm.css'
import './index.css'

// ** Images
import terminalIcon from 'images/terminal.svg'
import terminalGreyIcon from 'images/terminal_grey.svg'
import OpenIcon from 'images/pencil_open.svg'
import CloseIcon from 'mdi-material-ui/Close'
import Clipboard from 'mdi-material-ui/ContentCopy'

// ** Hooks
import { useAuth } from 'hooks/useAuth'
import { useSettings } from 'core/hooks/useSettings'
import StoragePreference from './Dialog'
import ErrorDialog from './DialogError'

const replaceSpecialCharacters = inputString => {
  const specialCharacters = [
    '@',
    '#',
    '$',
    '%',
    '^',
    '&',
    '*',
    '(',
    ')',
    ' ',
    '!',
    '~',
    ',',
    '.',
    ';',
    "'",
    '"',
    ':',
    '<',
    '>',
    '?',
    '/',
    '\\',
    '[',
    ']',
    '{',
    '}'
  ]

  const regex = new RegExp('[' + specialCharacters.join('\\') + ']', 'g')

  const replacedString = inputString.replace(regex, '_')

  return replacedString
}

const ConsoleLine = props => {
  // ** Props

  // ** Hooks
  const { user, setUser } = useAuth()
  const { saveSettings, settings, setDisconnect } = useSettings()
  const socketRef = useRef()
  const termRef = useRef()

  // ** State
  const [term, setTerm] = useState(null)
  const [socket, setSocket] = useState(null)
  const [state, setState] = useState(0) // states: 0-disconnected, 1-connecting, 2-connected
  const [command, setCommand] = useState('')
  const [commandHistory, setCommandHistory] = useState([])
  const [historyIndex, setHistoryIndex] = useState(-1)
  const [loading, setLoading] = useState(false)
  const [pingInterval, setPingInterval] = useState(null)
  const [height, setHeight] = useState(280) // Initial height of the div
  const [preferenceDialog, setPreferenceDialog] = useState(!user?.gamStatus)
  const [bucketName, setBucketName] = useState(null)
  const [copied, setCopied] = useState(false)
  const [error, setError] = useState(false)

  // ** Variables
  let commandLength = 0

  // ** Ref
  const terminalContainerRef = useRef(null)

  // ** Intialize Terminal
  useEffect(() => {
    if (!term) {
      const terminal = new Terminal({
        cols: Math.floor(window.innerWidth / 9.35),
        rows: Math.floor(height / 17)
      })
      setTerm(terminal)
      termRef.current = terminal
      terminal.onData(data => {
        // Handle data from the terminal (e.g., send it to the server)
        if (socket && socket.readyState === WebSocket.OPEN) {
          socket.send(data)
        }
      })

      terminal.open(terminalContainerRef.current)
    }

    return () => {
      // Cleanup logic when the component unmounts...
      disconnect(socketRef.current, termRef.current)
      if (pingInterval) {
        clearInterval(pingInterval)
        setPingInterval(null)
      }
    }
  }, [])

  // Auto copy selection logic implementation
  useEffect(() => {
    let selectionTimeout
    const handleSelectionChange = () => {
      clearTimeout(selectionTimeout)
      selectionTimeout = setTimeout(() => {
        const selectedText = window.getSelection().toString()
        if (selectedText) {
          navigator.clipboard.writeText(selectedText).then(() => setCopied(true))
        }
      }, 300)
    }

    document.addEventListener('mouseup', handleSelectionChange)
    document.addEventListener('touchend', handleSelectionChange)

    return () => {
      document.removeEventListener('mouseup', handleSelectionChange)
      document.removeEventListener('touchend', handleSelectionChange)
      clearTimeout(selectionTimeout)
    }
  }, [])

  // ** Functions
  const onChangeTerminal = (socket, setCommand) => {
    if (term) {
      let command = ''
      let cursorPosition = 0
      let historyIndex = -1
      let commandHistory = []
      let selectedText = ''
      term.onKey(function ({ key, domEvent }) {
        console.log('dom event:', domEvent, key)
        if (domEvent.key === 'Delete' || domEvent.code === 'Delete') {
          // Handle Delete key press
          // if (cursorPosition < command.length) {
          //   // Delete character at cursor position
          //   const newValue = command.slice(0, cursorPosition) + command.slice(cursorPosition + 1)
          //   command = newValue
          //   term.write('\b \b') // Simulate deletion on terminal
          //   term.write(command.slice(cursorPosition)) // Rewrite characters after deletion
          // }
        } else if (
          domEvent.key === 'PageUp' ||
          domEvent.code === 'PageUp' ||
          domEvent.key === 'PageDown' ||
          domEvent.code === 'PageDown'
        ) {
        } else if (domEvent.key === 'Enter') {
          if (command.trim() === 'clear') {
            // Clear the terminal output by simulating backspaces
            for (let i = 0; i < command.length; i++) {
              term.write('\b \b')
            }
            command = '' // Clear the command
            cursorPosition = 0 // Reset the cursor position
            term.scrollToTop()
            term.clear()
            const container = document.getElementById('container')
            container.scrollTop = 0
            return // Exit the function to prevent sending the command to the server
          }
          if (command.trim() === '') {
            socket.send('')
          } else {
            if (command.trim() === '') {
              return
            }
            if (['exit', "'exit'", '"exit"'].includes(command)) {
              disconnect(socket, term) // Treat variations of "exit" as a request to disconnect
              return
            }

            if (socket && socket.readyState === WebSocket.OPEN) {
              commandLength = command.length || 0
              socket.send(command)
              commandHistory = [command, ...commandHistory]
              historyIndex = -1
              cursorPosition = 0
              command = '' // Clear the input message
            }
          }
        } else if (domEvent.key === 'Tab') {
          // Handle Tab key press
          // For example, you can insert spaces or tabs based on your requirements
          const tabSize = 4 // Number of spaces to insert for each tab
          const spacesToInsert = tabSize - (cursorPosition % tabSize)
          const newValue = command.slice(0, cursorPosition) + ' '.repeat(spacesToInsert) + command.slice(cursorPosition)
          cursorPosition += spacesToInsert
          command = newValue
          term.write(' '.repeat(spacesToInsert)) // Write spaces to terminal
        } else if (domEvent.key === 'Backspace') {
          // Handle Backspace key press
          if (!command) return
          if (cursorPosition > 0 && cursorPosition !== command.length) {
            const newValue = command.slice(0, cursorPosition - 1) + command.slice(cursorPosition)
            term.write('\x1b[' + (command.length - cursorPosition) + 'C') // Move cursor to the right
            for (let i = 0; i < command.length; i++) {
              term.write('\b \b')
            }
            command = newValue
            cursorPosition = cursorPosition - 1
            term.write(newValue)
            const newCursorPosition = Math.max(0, cursorPosition)
            term.write('\x1b[' + (command.length - newCursorPosition) + 'D') // Move cursor to the left
          } else if (cursorPosition > 0 && cursorPosition === command.length) {
            const newValue = command.slice(0, cursorPosition - 1) + command.slice(cursorPosition)
            command = newValue

            // Move the cursor one position to the left
            term.write('\b')

            // Delete the character at the current cursor position
            term.write(' ')

            // Move the cursor back to its original position
            term.write('\b')

            // Update cursor positions
            cursorPosition = cursorPosition - 1
          }
        } else if (domEvent.key === 'ArrowLeft') {
          // Handle left arrow key press
          if (cursorPosition > 0) {
            cursorPosition = cursorPosition - 1
            term.write('\x1b[D') // Move cursor left
          }
        } else if (domEvent.key === 'ArrowRight') {
          // Handle right arrow key press
          if (cursorPosition < command.length) {
            cursorPosition = cursorPosition + 1
            term.write('\x1b[C') // Move cursor right
          }
        } else if (domEvent.key === 'ArrowUp' || domEvent.key === 'ArrowDown') {
          for (let i = 0; i < command.length; i++) {
            term.write('\b \b')
          }
          const newIndex =
            domEvent.key === 'ArrowUp'
              ? Math.min(historyIndex + 1, commandHistory.length - 1)
              : Math.max(historyIndex - 1, -1)
          historyIndex = newIndex
          command = newIndex === -1 ? '' : commandHistory[newIndex]
          cursorPosition = command.length
          term.write(command)
        } else if (domEvent.ctrlKey && domEvent.key === 'copy') {
          // Handle Ctrl+Shift+C (copy)
          selectedText = term.getSelection()
        } else if (domEvent.ctrlKey && domEvent.key === 'v') {
          navigator.clipboard.readText().then(selectedText => {
            if (selectedText) {
              const trimmedStr = selectedText.trim().replace(/\s+/g, ' ')
              const newValue = command.slice(0, cursorPosition) + trimmedStr + command.slice(cursorPosition)
              if (cursorPosition > 0 && cursorPosition !== command.length) {
                term.write('\x1b[' + (command.length - cursorPosition) + 'C') // Move cursor to the right
                for (let i = 0; i < command.length; i++) {
                  term.write('\b \b')
                }
                command = newValue
                cursorPosition = cursorPosition + 1
                term.write(newValue)
                const newCursorPosition = Math.max(0, cursorPosition)
                term.write('\x1b[' + (command.length - newCursorPosition) + 'D')
              } else {
                cursorPosition = cursorPosition + trimmedStr.length
                command = newValue
                term.write(trimmedStr)
              }
            }
          })
        } else if ((domEvent.ctrlKey && domEvent.key === 'a') || domEvent.key === 'Home') {
          // Handle Ctrl+A (move cursor to beginning of command)
          if (cursorPosition > 0) {
            term.write('\x1b[' + cursorPosition + 'D') // Move cursor back
            cursorPosition = 0
          }
        } else if (domEvent.key === 'End') {
          if (cursorPosition < command.length) {
            // Handle End pos (move cursor to last of command)
            const steps = command.length - cursorPosition
            term.write('\x1b[' + steps + 'C') // Move cursor back
            cursorPosition = command.length
          }
        } else if (domEvent.ctrlKey && domEvent.key === 'c') {
          // Send Ctrl+C to the server
          if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send('\x03')
            command = ''
            cursorPosition = 0
          }
        } else if (domEvent.ctrlKey && domEvent.key === 'x') {
          // Send Ctrl+X to the server
          if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send('\x18')
            command = ''
            cursorPosition = 0
          }
        } else if (domEvent.key === 'Insert') {
        } else if (domEvent.ctrlKey) {
        } else {
          if (cursorPosition !== command.length) {
            const newValue = command.slice(0, cursorPosition) + key + command.slice(cursorPosition)
            term.write('\x1b[' + (command.length - cursorPosition) + 'C') // Move cursor to the right
            for (let i = 0; i < command.length; i++) {
              term.write('\b \b')
            }
            command = newValue
            cursorPosition = cursorPosition + 1
            term.write(newValue)
            const newCursorPosition = Math.max(0, cursorPosition)
            term.write('\x1b[' + (command.length - newCursorPosition) + 'D')
          } else {
            // Update the input value for non-Enter, non-Backspace, ArrowLeft, and ArrowRight key presses
            const newValue = command.slice(0, cursorPosition) + key + command.slice(cursorPosition)
            cursorPosition = cursorPosition + 1
            command = newValue
            term.write(key)
          }
        }
      })
    }
  }
  const sendPing = newSocket => {
    newSocket.send('ping') // special message for keeping the connection on
  }

  const connect = () => {
    // Connect to terminal
    if (state > 0) {
      return
    }

    setState(1) // Connecting

    const userName = replaceSpecialCharacters(user?.name || '')
    const wsUrl = `${process.env.REACT_APP_GAM_SOCKET_URL}/terminal?clientId=${user?.id}&clientEmail=${
      user?.email
    }&customerId=${user?.customerId}&clientName=${userName?.replaceAll(/\s/g, '')?.toLowerCase()}${
      bucketName ? '&clientBucket=' + bucketName : ''
    }` // Updated WebSocket URL
    const newSocket = new WebSocket(wsUrl)
    socketRef.current = newSocket
    newSocket.onopen = () => {
      socketRef.current = newSocket
      setSocket(newSocket)
      setUser(state => ({ ...state, gamStatus: true }))
    }

    newSocket.onmessage = e => {
      // Handle messages from the server (e.g., update the terminal)
      const output = e.data

      if (output?.includes('loader')) {
        setLoading(false)
        setState(2)
        const intervalId = setInterval(() => sendPing(newSocket), 10000)
        setPingInterval(intervalId)
        const ref = () => {
          setLoading(true)
          setTimeout(() => {
            disconnect(newSocket, term)
          }, 8000)
        }
        setDisconnect(() => ref)
        return
      }

      if (output?.includes('error kys')) {
        setLoading(false)
        setState(0)
        setError(true)
        return
      }

      if (output?.includes('terminal kys')) {
        disconnect(newSocket, term)
        return
      }

      if (term) {
        if (commandLength) {
          for (let i = 0; i < commandLength; i++) {
            term.write('\b \b')
          }
          commandLength = 0
        }
        term.write(output)
        // const container = document.getElementById('container')
        // container.scrollTop = container.scrollHeight

        // term.scrollToBottom()
        // const container = document.getElementById('container')
        // console.log('----height----', container.scrollHeight)
        // container.scrollTop = 100
      }
    }

    onChangeTerminal(newSocket, setCommand)

    newSocket.onerror = err => {
      disconnect(socket, term)
      console.error('Connection error:', err)
    }

    newSocket.onclose = () => {
      console.log('----close----')
      disconnect(socket, term)
    }
  }

  const disconnect = (socket, term) => {
    setLoading(false)
    setCommand('')
    setCommandHistory([])
    setHistoryIndex(-1)
    clearInterval(pingInterval)
    setPingInterval(null)

    // Disconnect from terminal
    if (socket) {
      socket.close()
    }
    if (state > 0) {
      setState(0) // Disconnected
    }
    if (term) {
      term.scrollToTop()
      term.clear()
      term.dispose()
    }
    setDisconnect(null)
    saveSettings(state => ({ ...state, consoleLine: false, timer: false }))
  }

  const connectingTerminal = () => {
    setLoading(true)
    connect()
  }

  const disconnectTerminal = (socket, term) => {
    setLoading(true)
    setTimeout(() => {
      disconnect(socket, term)
    }, 8000)
  }

  // On top layout
  const onResize = (event, { node, size, handle }) => {
    setHeight(size.height)
    term.resize(Math.floor(window.innerWidth / 9.35), Math.floor(size.height / 17))
  }

  const handleClose = () => {
    setCopied(false)
  }

  return (
    <Box
      sx={{
        borderTop: theme => `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme => theme.palette.grey[50],
        zIndex: theme => theme.zIndex.drawer + 100,
        height: 'fit-content',
        width: '100vw',
        position: 'fixed',
        left: 0,
        bottom: 0
      }}
    >
      <Snackbar
        open={copied}
        onClose={handleClose}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 2 }}>
            <Clipboard sx={{ fontSize: '0.975rem' }} />
            <Typography sx={{ color: 'white', fontSize: '0.925rem' }}>Copied to clipboard !</Typography>{' '}
          </Box>
        }
        autoHideDuration={1500}
        key={'top' + 'right'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      {preferenceDialog ? <StoragePreference setBucketName={setBucketName} email={user?.email} /> : null}
      <ErrorDialog disconnectTerminal={disconnect} error={error} setError={setError} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          height: 47,
          py: 1,
          px: 2,
          borderBottom: theme => `1px solid ${theme.palette.grey[400]}`
        }}
      >
        <Box sx={{ display: 'flex', gap: 3, justifyContent: 'start', alignItems: 'center' }}>
          <img height={32} width={32} alt='' src={terminalGreyIcon} />
          <Box>
            <div style={{ display: 'flex', alignItems: 'start', padding: '10px 0px 0px 10px' }}>
              <Typography
                sx={{
                  font: '500 10px/16px Roboto,Helvetica Neue,sans-serif !important',
                  letterSpacing: '8px !important',
                  textTransform: 'uppercase'
                }}
              >
                GAM
              </Typography>
              <Typography
                sx={{
                  font: '400 12px/14px Google Sans,Helvetica Neue,sans-serif !important',
                  letterSpacing: 'normal !important',
                  fontSize: 14,
                  color: '#3c4043'
                }}
              >
                (v7.00.06)
              </Typography>
            </div>
            <Typography
              sx={{
                font: '400 16px/24px Google Sans,Helvetica Neue,sans-serif !important',
                letterSpacing: 'normal !important',
                color: '#3c4043',
                padding: '0 8px 2px 8px'
              }}
            >
              Terminal
            </Typography>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'end' }}>
          <Button
            variant='outlined'
            onClick={connectingTerminal}
            disabled={state === 1 || state === 2 || loading}
            sx={{
              borderRadius: '3px',
              px: 2,
              fontSize: 12
            }}
            startIcon={<img alt='' src={OpenIcon} />}
          >
            {state === 0 && !loading && 'Connect Terminal'}
            {(state === 1 || state === 0) && loading && 'Connecting...'}
            {state === 2 && !loading && 'Connected'}
            {state === 2 && loading && 'Disconnecting'}
          </Button>
          <IconButton variant='text' sx={{ color: 'text.secondary' }} onClick={() => disconnectTerminal(socket, term)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Resizable
        height={height}
        width='100vw'
        axis='y'
        lockAspectRatio
        draggableOpts={{ axis: 'y', grid: [10, 10] }}
        resizeHandles={['nw']}
        maxConstraints={[100, 450]}
        minConstraints={[100, 280]}
        onResize={onResize}
        handle={
          <Box
            sx={{
              width: '32px',
              height: '24px',
              position: 'absolute',
              left: '50vw',
              top: 5,
              px: 3,
              pb: 1,
              cursor: 's-resize',
              '&:hover': {
                '.child': { backgroundColor: '#1a73e8', borderColor: '#1a73e8' }
              },
              '&:active': {
                '.child': { backgroundColor: '#1a73e8', borderColor: '#1a73e8' }
              }
            }}
          >
            <Box
              className='child'
              sx={{
                width: '22px',
                height: '3px',
                borderRadius: '10px',
                border: '1px solid'
              }}
            />
          </Box>
        }
      >
        <Box height={height}>
          <Box
            className='mainCont'
            id='container'
            sx={{ display: loading || state === 0 ? 'none' : '', maxHeight: height }}
          >
            <div className='terminalCont' style={{ maxHeight: `${height} !important` }}>
              <div className='terminalContainer' ref={terminalContainerRef}></div>
            </div>
          </Box>
          {state === 0 && !loading ? (
            <Box
              sx={{
                height: height,
                backgroundColor: '#FFF',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 5
              }}
            >
              <Typography
                sx={{
                  font: '400 16px/24px Google Sans,Helvetica Neue,sans-serif !important'
                }}
              >
                Start your terminal
              </Typography>
            </Box>
          ) : null}
          {loading ? (
            <Box
              sx={{
                height: 280,
                backgroundColor: '#FFF',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 5
              }}
            >
              <img height={52} width={52} alt='' src={terminalIcon} />
              <CircularProgress size='1rem' sx={{ ml: 0.5 }} />
              {state === 2 && loading ? (
                <Typography
                  sx={{
                    font: '400 16px/24px Google Sans,Helvetica Neue,sans-serif !important'
                  }}
                >
                  Disconnecting your terminal, please wait.
                </Typography>
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Resizable>
    </Box>
  )
}

export default ConsoleLine
