// ** React Imports
import React, { Fragment, useState } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { usersSelector } from 'store/users'

// ** API CALLS
import { updateGroupTemplate } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Icon Imports
import EditIcon from 'mdi-material-ui/PencilOutline'
import CustomAlertMessage from '../alert/CustomAlertMessage'

// ** Styled Box 
const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    boxShadow: theme.shadows[5],
    paddingTop: "0.125rem",
    paddingBottom: "0.125rem",
    paddingRight: "0.725rem",
    paddingLeft: "0.725rem",
    borderRadius: "1rem",
    cursor: "pointer",
    width: "fit-content"
}))

const defaultValues = {
    name: '',
    description: ''
}


const UpdateGroupTemplateDialog = (props) => {

    // ** Props
    const { setGroupTemplate, group } = props

    // ** Hooks
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: { name: group?.name, description: group?.description } })


    // ** State
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({ success: false, error: false, value: "" })

    // ** Functions
    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    const handleCloseMessage = () => setMessage({ success: false, error: false, value: "" })

    const onSubmit = async (body) => {
        setLoading(true)
        try {
            const data = await updateGroupTemplate(group.id, { data: { ...group, ...body } })
            setGroupTemplate(state => {
                return state.map(group =>
                    group.id.toString() === data.id.toString()
                        ? { group, ...data }
                        : group
                )
            })
            setMessage({ success: true, error: false, value: "Group was updated successfully" })
            setLoading(false)
            setOpen(false)
        } catch (error) {
            console.log("errr", error)
            setMessage({ success: false, error: true, value: "There was an error while updating the group" })
            setLoading(false)
        }
    }


    return (
        <Fragment>
            <IconButton
                size="medium"
                edge="start"
                sx={{ color: "text.secondary" }}
                onClick={handleClickOpen}
            >
                <EditIcon />
            </IconButton>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id='alert-dialog-title'>Update group</DialogTitle>
                <DialogContent>
                    <form id="update-folder-form" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='name'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                variant="standard"
                                                value={value ?? ""}
                                                label='Rename Template'
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='description'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                variant="standard"
                                                value={value ?? ""}
                                                label='Template Descritpion'
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions className='dialog-actions-dense' sx={{ mt: "1rem" }}>
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                        size="small"
                        variant="text"
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        endIcon={(loading ?
                            <CircularProgress sx={{ color: 'common.white', mt: 0 }} size="0.825rem" /> : null
                        )}
                        type="submit"
                        form="update-folder-form"
                        variant="contained"
                        size="small"
                        sx={{ fontWeight: 600, primary: "common.blue" }}
                    >
                        save
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomAlertMessage
                message={message}
                handleCloseMessage={handleCloseMessage}
            />
        </Fragment>
    )
}

export default UpdateGroupTemplateDialog