// ** React Imports
import React, { Fragment, useState, useEffect } from 'react'

// ** API CALLS
import { getSharedDriveSettings, updateSharedDriveSettings } from 'configs/axios/api_helper'

// ** Hooks
import { useAuth } from 'hooks/useAuth'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

// ** Custom Components
import CustomAvatar from 'core/components/mui/avatar'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Icon Imports
import SettingsIcon from 'mdi-material-ui/CogOutline'

const SharedDriveSettingsDialog = props => {
  // ** Props
  const { setFolders, setMessage, folder } = props

  // ** Hooks
  const { user } = useAuth()
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      adminManagedRestrictions: false,
      domainUsersOnly: false,
      driveMembersOnly: false,
      copyRequiresWriterPermission: false
    }
  })

  // ** State
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [settings, setSettings] = useState({})

  // ** Fetch Users
  useEffect(() => {
    const fetchSharedDriveSettings = async () => {
      try {
        const response = await getSharedDriveSettings(user?.id, folder?.id)
        const { data } = response
        reset({
          adminManagedRestrictions: data?.restrictions?.adminManagedRestrictions ?? false,
          domainUsersOnly: data?.restrictions?.domainUsersOnly ?? false,
          driveMembersOnly: data?.restrictions?.driveMembersOnly ?? false,
          copyRequiresWriterPermission: data?.restrictions?.copyRequiresWriterPermission ?? false,
          sharingFoldersRequiresOrganizerPermission:
            data?.restrictions?.sharingFoldersRequiresOrganizerPermission ?? false
        })
      } catch (error) {
        setSettings({})
      }
    }
    if (open) {
      fetchSharedDriveSettings()
    }
  }, [open])

  // ** Functions
  const handleClickOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const onSubmit = async body => {
    setLoading(true)
    try {
      const data = await updateSharedDriveSettings(user?.id, folder?.id, { restrictions: { ...body } })
      setMessage({ success: true, error: false, value: 'Drive settings was updated successfully' })
      setLoading(false)
      setOpen(false)
    } catch (error) {
      console.log('error', error)
      setMessage({ success: false, error: true, value: 'There was an error while updating the drive settings' })
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <IconButton size='medium' edge='start' sx={{ color: 'text.secondary', mx: '0.325rem' }} onClick={handleClickOpen}>
        <SettingsIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle id='alert-dialog-title'>Shared Drive Settings</DialogTitle>
        <DialogContent>
          <Grid container spacing={4} sx={{ px: '1rem' }}>
            <form id='shared-drive-settings' onSubmit={handleSubmit(onSubmit)}>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mt: '0.925rem' }}>
                  <Controller
                    name='adminManagedRestrictions'
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        label='Allow managers to modify shared drive settings'
                        control={<Checkbox {...field} checked={!!field.value} />}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ my: 4 }}>
                <Typography sx={{ fontWeight: 600, fontSize: 14 }}>Access</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name='domainUsersOnly'
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        label={`Allow people outside of ${user?.primaryEmail?.split('@')?.[1] || ''} to access files`}
                        control={<Checkbox {...field} checked={!!field.value} />}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mt: '0.925rem' }}>
                  <Controller
                    name='driveMembersOnly'
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        label="Allow people who aren't shared drive members to access files"
                        control={<Checkbox {...field} checked={!!field.value} />}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ my: 4 }}>
                <Typography sx={{ fontWeight: 600, fontSize: 14 }}>Role permissions</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name='sharingFoldersRequiresOrganizerPermission'
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        label='Allow content managers to share folders'
                        control={<Checkbox {...field} checked={!!field.value} />}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mt: '0.925rem' }}>
                  <Controller
                    name='copyRequiresWriterPermission'
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        label='Viewers and commenters can download, print, and copy files'
                        control={<Checkbox {...field} checked={!!field.value} />}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </form>
          </Grid>
        </DialogContent>
        <DialogActions className='dialog-actions-dense' sx={{ mt: '1rem' }}>
          <Button disabled={loading} onClick={handleClose} size='small' variant='text' color='inherit'>
            Cancel
          </Button>
          <Button
            disabled={loading}
            endIcon={loading ? <CircularProgress sx={{ color: 'common.white', mt: 0 }} size='0.825rem' /> : null}
            type='submit'
            form='shared-drive-settings'
            variant='contained'
            size='small'
            sx={{ fontWeight: 600, primary: 'common.blue' }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default SharedDriveSettingsDialog
