// ** React Imports
import React, { Fragment, useEffect, useState } from 'react'

// ** API CALLS
import { createGroup, getDomainsList, getGroupTemplates, updateGroupSettings } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import Autocomplete from '@mui/material/Autocomplete'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Icon Imports
import EditIcon from 'mdi-material-ui/PencilOutline'
import CustomAlertMessage from '../alert/CustomAlertMessage'

// ** Hooks
import { useAuth } from 'hooks/useAuth'
import { addGroup, groupsSelector } from 'store/groups'
import { useDispatch, useSelector } from 'react-redux'

// ** Styled Box 
const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    boxShadow: theme.shadows[5],
    paddingTop: "0.125rem",
    paddingBottom: "0.125rem",
    paddingRight: "0.725rem",
    paddingLeft: "0.725rem",
    borderRadius: "1rem",
    cursor: "pointer",
    width: "fit-content",
    gap: 5
}))

const defaultValues = {
    name: '',
    description: '',
    email: '',
    template: '',
    domain: ''
}


const AddGroupDialog = (props) => {

    // ** Props
    const { setGroupTemplate } = props

    // ** Hooks
    const dispatch = useDispatch()
    const auth = useAuth()
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues })

    // ** Selectors
    const { groups, success, error, loading } = useSelector(groupsSelector);

    // ** State
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState({ success: false, error: false, value: "" })
    const [groupTemplatesList, setGroupTemplatesList] = useState([])
    const [domains, setDomains] = useState([])

    // ** Fecth Data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const domainsResponse = await getDomainsList(auth.user?.customerId).then(res => res.data.domains)
                setDomains(domainsResponse)
                const groupTemplatesResponse = await getGroupTemplates().then(res => res.data)
                setGroupTemplatesList(groupTemplatesResponse)
            } catch (error) {
                console.log("error :", error)
            }
        }
        if (open) {
            fetchData()
        }
    }, [open])

    useEffect(() => {
        if (success === "CREATE_GROUP") {
            setMessage({ success: true, error: false, value: "Group was created successfully" })
            setOpen(false)
        }
        if (error === "CREATE_GROUP")
            setMessage({ success: false, error: true, value: "There was an error while creating the group" })
    }, [success, error])

    // ** Functions
    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    const handleCloseMessage = () => setMessage({ success: false, error: false, value: "" })

    const onSubmit = async (body) => {
        const data = {
            name: body.name,
            description: body.description,
            email: body.email + "@" + body.domain?.domainName
        }
        dispatch(addGroup(data, body.template?.groupSetting))
    }


    return (
        <Fragment>
            <StyledBox onClick={handleClickOpen}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 36 36">
                    <path fill="#34A853" d="M16 16v14h4V20z"></path>
                    <path fill="#4285F4" d="M30 16H20l-4 4h14z"></path>
                    <path fill="#FBBC05" d="M6 16v4h10l4-4z"></path>
                    <path fill="#EA4335" d="M20 16V6h-4v14z"></path>
                    <path fill="none" d="M0 0h36v36H0z"></path>
                </svg>
                <Typography sx={{ color: "text.secondary", fontSize: "0.825rem", fontWeight: 600 }}>
                    New group
                </Typography>
            </StyledBox>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id='alert-dialog-title'>Create group</DialogTitle>
                <DialogContent>
                    <form id="update-folder-form" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='name'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                variant="standard"
                                                value={value ?? ""}
                                                label='Group name*'
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={4}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='email'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    variant="standard"
                                                    value={value ?? ""}
                                                    label='Group email*'
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant='standard' fullWidth>
                                        <Controller
                                            name='domain'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <Autocomplete
                                                    id='autocomplete-domains'
                                                    getOptionLabel={option => option.domainName || ""}
                                                    renderInput={params => <TextField {...params} variant="standard" label="Domains*" />}
                                                    options={domains}
                                                    value={value}
                                                    onChange={(event, value) => onChange(value)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant='standard' fullWidth>
                                    <Controller
                                        name='template'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <Autocomplete
                                                id='autocomplete-templates'
                                                getOptionLabel={option => option.name || ""}
                                                renderInput={params => <TextField {...params} variant="standard" label="Templates*" />}
                                                options={groupTemplatesList}
                                                value={value}
                                                onChange={(event, value) => onChange(value)}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='description'
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                variant="standard"
                                                value={value ?? ""}
                                                label='Group Descritpion'
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions className='dialog-actions-dense' sx={{ mt: "1rem" }}>
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                        size="small"
                        variant="text"
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        endIcon={(loading ?
                            <CircularProgress sx={{ color: 'common.white', mt: 0 }} size="0.825rem" /> : null
                        )}
                        type="submit"
                        form="update-folder-form"
                        variant="contained"
                        size="small"
                        sx={{ fontWeight: 600, primary: "common.blue" }}
                    >
                        create
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomAlertMessage
                message={message}
                handleCloseMessage={handleCloseMessage}
            />
        </Fragment>
    )
}

export default AddGroupDialog