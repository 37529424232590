// ** React imports
import React, { useEffect, useState } from 'react'

// ** Redux Imports
import { editUser, usersSelector } from 'store/users'
import { useDispatch, useSelector } from 'react-redux'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Hooks
import { useAuth } from 'hooks/useAuth'

// ** Styled Box
const Container = styled(Box, { shouldForwardProp: prop => prop !== 'hover' })(({ theme, hover }) => ({
  padding: '1.425rem',
  cursor: 'pointer',
  backgroundColor: '#fff',
  transition: 'all .1s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2)
  },
  ...(hover
    ? {
        boxShadow: theme.shadows[10],
        margin: '12px -12px',
        cursor: 'default'
      }
    : {}),
  ...(!hover ? { '&:hover': { backgroundColor: theme.palette.grey[100] } } : {})
}))

const defaultValues = {
  recoveryEmail: '',
  primaryEmail: '',
  address: '',
  aaddressType: '',
  phone: '',
  phoneType: '',
  emailType: 'work',
  includeInGlobalAddressList: false
}

const ContactInformation = props => {
  // ** Hooks
  const auth = useAuth()
  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm({ defaultValues })

  // ** Selectors
  const { user, loading, success, error } = useSelector(usersSelector)

  // ** State
  const [show, setShow] = useState(false)

  // ** Set Data
  useEffect(() => {
    if (user !== undefined) {
      reset({
        ...defaultValues,
        recoveryEmail: user.recoveryEmail || '',
        recoveryPhone: user.recoveryPhone || '',
        primaryEmail: user.primaryEmail || '',
        ...(user.phones && user.phones?.length > 0 && { phone: user.phones[0]?.value }),
        ...(user.phones && user.phones?.length > 0 && { phoneType: user.phones[0]?.type }),
        ...(user.addresses && user.addresses?.length > 0 && { address: user.addresses[0]?.formatted }),
        ...(user.addresses && user.addresses?.length > 0 && { addressType: user.addresses[0]?.type }),
        includeInGlobalAddressList: user.includeInGlobalAddressList || false
      })
    }
  }, [user])

  // ** Functions
  const toggleHandler = () => {
    if (!show) setShow(true)
  }

  const onSubmit = data => {
    const { recoveryPhone, recoveryEmail, address, addressType, phone, phoneType, includeInGlobalAddressList } = data
    let body = {
      phones: [{ value: phone, type: phoneType }],
      recoveryPhone,
      recoveryEmail,
      addresses: [{ formatted: address, type: addressType }],
      includeInGlobalAddressList: includeInGlobalAddressList
    }
    dispatch(editUser(user?.id, body))
    setShow(false)
  }

  console.log('---form----', watch(), user)

  return (
    <Container hover={show} onClick={toggleHandler}>
      {show ? (
        <Grid item container xs={12} sx={{ position: 'relative' }}>
          <Grid item xl={3}>
            <Typography sx={{ fontWeight: 600, fontSize: '0.775rem' }}>Contact Information</Typography>
          </Grid>
          <Grid item xs={12} xl={8}>
            <form id='contact-form' onSubmit={handleSubmit(onSubmit)}>
              <Grid item container xl={8} spacing={4} sx={{ py: '0.5rem' }}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Controller
                      name='recoveryEmail'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          variant='standard'
                          value={value}
                          label='Secondary Email'
                          onChange={onChange}
                          error={Boolean(errors.firstName)}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Controller
                      name='recoveryPhone'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          variant='standard'
                          value={value}
                          label='recovery Phone'
                          onChange={onChange}
                          error={Boolean(errors.lastName)}
                        />
                      )}
                    />
                    <FormHelperText>
                      The phone number must be in the E.164 format, starting with the plus sign (+). Example:
                      +16506661212
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container xl={8} spacing={4} sx={{ py: '0.5rem' }}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Controller
                      name='primaryEmail'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          variant='standard'
                          type='email'
                          value={value}
                          label='Email Address'
                          onChange={onChange}
                          error={Boolean(errors.email)}
                          placeholder='carterleonard@gmail.com'
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id='email-type-label'>Type</InputLabel>
                    <Controller
                      name='emailType'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Select
                          variant='standard'
                          value={value}
                          label='Type'
                          onChange={onChange}
                          labelId='email-type-label'
                        >
                          <MenuItem value='work'>Work</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container xl={8} spacing={4} sx={{ py: '0.5rem' }}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Controller
                      name='phone'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          variant='standard'
                          type='tel'
                          value={value}
                          label='Phone'
                          onChange={onChange}
                          error={Boolean(errors.phone)}
                          placeholder='+16506661212'
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id='phone-type-label'>Type</InputLabel>
                    <Controller
                      name='phoneType'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Select
                          variant='standard'
                          value={value}
                          label='Type'
                          onChange={onChange}
                          labelId='phone-type-label'
                        >
                          <MenuItem value=''>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value='home'>Home</MenuItem>
                          <MenuItem value='mobile'>Mobile</MenuItem>
                          <MenuItem value='work'>Work</MenuItem>
                          <MenuItem value='other'>Other</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container xl={8} spacing={4} sx={{ py: '0.5rem' }}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Controller
                      name='address'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField variant='standard' type='text' value={value} label='Address' onChange={onChange} />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id='address-type-label'>Type</InputLabel>
                    <Controller
                      name='addressType'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Select
                          variant='standard'
                          value={value}
                          label='Type'
                          onChange={onChange}
                          labelId='address-type-label'
                        >
                          <MenuItem value=''>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value='home'>Home</MenuItem>
                          <MenuItem value='work'>Work</MenuItem>
                          <MenuItem value='other'>Other</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xl={8} sx={{ py: '1rem' }}>
                <FormControl>
                  <Controller
                    name='includeInGlobalAddressList'
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        label="Automatically share User's contact information."
                        sx={errors.checkbox ? { color: 'error.main' } : null}
                        control={
                          <Checkbox
                            {...field}
                            checked={!!field.value}
                            name='validation-basic-checkbox'
                            sx={errors.checkbox ? { color: 'error.main' } : null}
                          />
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </form>
          </Grid>
          <Divider sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', width: '100%' }} />
          <Grid item xs={12} sx={{ textAlign: 'end', mt: '1rem' }}>
            <Button sx={{ fontWeight: 600, color: 'text.secondary' }} variant='text' onClick={() => setShow(false)}>
              CANCEL
            </Button>
            <Button type='submit' form='contact-form' variant='text' sx={{ fontWeight: 600 }}>
              SAVE
            </Button>
          </Grid>
          <Backdrop
            open={loading == 'UPDATE_USER'}
            sx={{
              position: 'absolute',
              backgroundColor: 'transparent',
              backdropFilter: 'blur(5px)',
              color: theme => theme.palette.common.blue,
              zIndex: theme => theme.zIndex.mobileStepper - 1
            }}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        </Grid>
      ) : (
        <Grid container item xs={12} spacing={6}>
          <Grid item xs={12} xl={3}>
            <Typography sx={{ fontWeight: 600, fontSize: '0.775rem' }}>Contact Information</Typography>
          </Grid>
          <Grid container item xs={12} xl={8} spacing={6}>
            <Grid item xs={12}>
              <Typography variant='body2' sx={{ fontSize: '0.775rem' }}>
                Email (Work)
              </Typography>
              <Typography sx={{ fontSize: '0.925rem' }}>{user?.primaryEmail || 'Add work email'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' sx={{ fontSize: '0.775rem' }}>
                Phone ({user?.phones?.[0]?.type || 'Home'})
              </Typography>
              <Typography sx={{ fontSize: '0.925rem' }}>
                {user?.phones?.length > 0 ? user?.phones?.[0]?.value || 'Add a phone number' : 'Add a phone number'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' sx={{ fontSize: '0.775rem' }}>
                Address ({user?.addresses?.[0]?.type || 'Home'})
              </Typography>
              <Typography sx={{ fontSize: '0.925rem' }}>
                {user?.addresses?.length > 0
                  ? user?.addresses?.[0]?.formatted || 'Add home address'
                  : 'Add home address'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default ContactInformation
