

// ** React imports
import React, { useEffect, useState } from 'react'

// ** Api Calls
import { updateCalendar } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Snackbar from '@mui/material/Snackbar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

// ** Import Data
import { timezone } from '@fake-db/timezone'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Custom Components
import CustomAlertMessage from '../../alert/CustomAlertMessage'

// ** Icon Imports
import DeleteIcon from 'mdi-material-ui/Delete'
import DeleteDialog from './dialogs/DeleteDialog'


// ** Styled Box
const Container = styled(Box, { shouldForwardProp: (prop) => prop !== "hover" })(({ theme, hover }) => ({
    padding: "1.425rem",
    cursor: "pointer",
    backgroundColor: "#fff",
    transition: "all .1s ease-in-out",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
    },
    ...(hover ? {
        boxShadow: theme.shadows[10],
        margin: "12px -12px",
        cursor: "default"
    } : {}),
    ...(!hover ? { "&:hover": { backgroundColor: theme.palette.grey[100] } } : {})
}
))

const defaultValues = {
    summary: "",
    timeZone: "UTC-8",
    description: "",
    location: ""
}



const CalendarCard = (props) => {

    // ** Props
    const { user, calendar, setCalendars } = props

    // ** Hooks
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        watch
    } = useForm({ defaultValues })

    // ** State
    const [show, setShow] = useState(false)
    const [message, setMessage] = useState({ success: false, error: false })
    const [loading, setLoading] = useState(true)

    // ** Fetch Data
    useEffect(() => {
        if (calendar?.id) {
            reset({
                summary: calendar.summary,
                description: calendar.description,
                timeZone: calendar.timeZone,
                location: calendar.location
            })
        }

    }, [calendar])

    // ** Functions
    const toggleHandler = () => {
        if (!show)
            setShow(true)
    }


    const onSubmit = async (data) => {
        setLoading(true)
        try {
            const response = await updateCalendar(user.primaryEmail, user.id, data)
            setLoading(false)
            setMessage({ error: false, success: true, value: "Calendar was update successfully" })
        } catch (error) {
            setLoading(false)
            setMessage({ error: true, success: false, value: "There was an error updating the calendar" })
        }
    }



    const handleCloseMessage = () => {
        setMessage({ success: false, error: false, value: "" })
    }

    const deleteHandler = (event) => {
        event.stopPropagation()
        console.log("hiii")
    }



    return (
        <Container hover={show} onClick={toggleHandler}>
            {show ?
                <Grid item container xs={12}>
                    <Grid item xs={12} xl={8} sx={{ py: "0.5rem" }}>
                        <form id="calendar-form" onSubmit={handleSubmit(onSubmit)}>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }} spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Title :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='summary'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    variant="standard"
                                                    value={value ?? ""}
                                                    label='Title'
                                                    onChange={onChange}
                                                    error={Boolean(errors.title)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }} spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Location :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='location'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    variant="standard"
                                                    value={value ?? ""}
                                                    label='Location'
                                                    onChange={onChange}
                                                    error={Boolean(errors.location)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Time Zone :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='timeZone'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <Autocomplete
                                                    defaultValue={timezone?.find(time => time?.value === calendar?.timeZone)}
                                                    value={timezone?.find(zone => zone?.value === value)}
                                                    onChange={(event, values) => onChange(values?.value)}
                                                    options={timezone}
                                                    getOptionLabel={option => option.name}
                                                    renderInput={params =>
                                                        <TextField
                                                            variant="standard"
                                                            {...params}
                                                            label='TimeZone'
                                                        />}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Description :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='description'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    variant="standard"
                                                    multiline
                                                    minRows={4}
                                                    value={value ?? ""}
                                                    label='Description'
                                                    onChange={onChange}
                                                    error={Boolean(errors.description)}
                                                    placeholder='description'
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                    <Grid item xs={12} sx={{ textAlign: "end", mt: '1rem' }}>
                        <Button sx={{ fontWeight: 600, color: "text.secondary" }} variant="text" onClick={() => setShow(false)}>
                            CANCEL
                        </Button>
                        <Button
                            type='submit'
                            form="calendar-form"
                            variant="text"
                            sx={{ fontWeight: 600 }}
                        >
                            SAVE
                        </Button>
                    </Grid>
                </Grid >
                :
                <Grid container item xs={12} spacing={6}>
                    <Grid container item xs={11}>
                        <Grid item xs={12} xl={3}>
                            <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                                {calendar?.summary}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} xl={8} spacing={6}>
                            <Grid item xs={12}>
                                <Typography variant="body2" sx={{ fontSize: "0.775rem" }}>
                                    Edit {calendar?.summary}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        <DeleteDialog
                            user={user}
                            setCalendars={setCalendars}
                            setMessage={setMessage}
                            calendar={calendar}
                        />
                    </Grid>
                </Grid>
            }
            <CustomAlertMessage
                message={message}
                handleCloseMessage={handleCloseMessage}
            />
        </Container >
    )

}

export default CalendarCard