// ** React Imports
import React, { useEffect, useState } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { groupsSelector } from 'store/groups'

// ** Third Party Libraries
import moment from 'moment'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'



// ** Styled Tab List
const Container = styled(Box)(({ theme }) => ({
    paddingLeft: "1rem",
    paddingTop: "1rem",
    marginBottom: "2rem",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(5),
    },
}))

// ** Styled Link
const Link = styled(Typography, { shouldForwardProp: (prop) => prop !== 'active' })(({ theme, active }) => ({
    fontFamily: "Roboto",
    textTransform: "uppercase",
    fontSize: "0.925rem",
    fontWeight: 600,
    lineHeight: "16px",
    marginTop: "0.525rem",
    marginBottom: "0.525rem",
    paddingTop: "0.875rem",
    paddingBottom: "0.875rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    borderRadius: 3,
    cursor: "pointer",
    transition: ".25s",
    ...(active ? { backgroundColor: theme.palette.grey[300] } : {}),
    "&:hover": { backgroundColor: theme.palette.grey[300] }
}))


const GeneralInfo = (props) => {

    // ** Props
    const { activeTab, changeTabsHandler, tabs } = props
    // ** State
    const [units, setUnits] = useState([])
    // const [offset, setOffset] = useState(0);

    // ** Selectors
    const { group, success, error, loading } = useSelector(groupsSelector);

    useEffect(() => {
        // if (group?.orgUnitPath) {
        //     setUnits(group?.orgUnitPath?.split("/")?.filter(el => el !== ""))
        // }
    }, [])


    console.log("grrr", group)


    return (
        <Card
            sx={{
                borderRadius: 0.5,
                py: "0.5rem",
                border: theme => `solid 1px ${theme.palette.grey[300]}`,
            }}>
            <Container>
                <Box item xs={8} sx={{ display: "flex", flexDirection: "column", px: "1rem" }}>
                    <Typography sx={{ fontSize: "1.725rem" }}>
                        {(group?.name || "----")}
                    </Typography>
                    <Typography sx={{ my: "0.325rem" }}>
                        {group?.email}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: "0.785rem", fontWeight: 600 }}>
                        Direct Member Count: {group?.directMembersCount || 0}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: "0.785rem", fontWeight: 600 }}>
                        Admin Created: {group?.adminCreated ? "True" : "False"}
                    </Typography>
                </Box>
            </Container>
            <Divider sx={{ borderBottom: theme => `1px solid ${theme.palette.grey[300]}` }} />
            <Box sx={{ px: "1rem" }}>
                {tabs.map((el, index) => (
                    <Link
                        active={activeTab === index}
                        variant="body2"
                        key={index}
                        onClick={() => changeTabsHandler(index)}
                    >
                        {el}
                    </Link>
                ))}
            </Box>
        </Card>
    )
}

export default GeneralInfo