

// ** React imports
import React, { useEffect, useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'


// ** Icons Imports
import EyeOutline from 'mdi-material-ui/EyeOutline'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'
import KeyIcon from "mdi-material-ui/ShieldKeyOutline"

// ** Styled Box
const Container = styled(Box, { shouldForwardProp: (prop) => prop !== "hover" })(({ theme, hover }) => ({
    padding: "1.425rem",
    cursor: "pointer",
    backgroundColor: "#fff",
    transition: "all .1s ease-in-out",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
    },
    ...(hover ? {
        boxShadow: theme.shadows[10],
        margin: "12px -12px",
        cursor: "default"
    } : {}),
    ...(!hover ? { "&:hover": { backgroundColor: theme.palette.grey[100] } } : {})
}
))

const defaultValues = {
    makeAdmin: false,
}



const UpdateCard = (props) => {

    // ** Props
    const { user } = props

    // ** Hooks
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({ defaultValues })

    // ** State
    const [show, setShow] = useState(false)

    // ** Set Data
    useEffect(() => {
        if (user !== undefined) {
            reset({ makeAdmin: user.isAdmin || false })
        }
    }, [user])

    // ** Functions
    const toggleHandler = () => {
        if (!show)
            setShow(true)
    }


    const onSubmit = (data) => {
        console.log("data", data)
        setShow(false)
    }




    return (
        <Container hover={show} onClick={toggleHandler}>
            {show ?
                <Grid item container xs={12}>
                    <Grid item xl={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                            Update Send As
                        </Typography>
                    </Grid>
                    <Grid item xs={12} xl={8} sx={{ py: "0.5rem" }}>
                        <form id="role-form" onSubmit={handleSubmit(onSubmit)}>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Send as Email:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='sendAsEmail'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    variant="standard"
                                                    value={value}
                                                    onChange={onChange}
                                                    error={Boolean(errors.sendAsEmail)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }} spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Display Name:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='displayName'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    variant="standard"
                                                    value={value}
                                                    onChange={onChange}
                                                    error={Boolean(errors.displayName)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Reply To Email:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='replyToAddress'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    variant="standard"
                                                    value={value}
                                                    onChange={onChange}
                                                    error={Boolean(errors.replyToAddress)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }} spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Set this account as default:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormControl fullWidth sx={{ mt: "0.925rem" }}>
                                        <Controller
                                            name='isDefault'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    sx={errors.checkbox ? { color: 'error.main' } : null}
                                                    control={
                                                        <Switch
                                                            {...field}
                                                            name='validation-basic-checkbox'
                                                            sx={errors.checkbox ? { color: 'error.main' } : null}
                                                        />
                                                    }
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                    <Grid item xs={12} sx={{ textAlign: "end", mt: '1rem' }}>
                        <Button sx={{ fontWeight: 600, color: "text.secondary" }} variant="text" onClick={() => setShow(false)}>
                            CANCEL
                        </Button>
                        <Button
                            type='submit'
                            form="role-form"
                            variant="text"
                            sx={{ fontWeight: 600 }}
                        >
                            SAVE
                        </Button>
                    </Grid>
                </Grid >
                :
                <Grid container item xs={12} spacing={6}>
                    <Grid item xs={12} xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            Send As email settings

                        </Typography>
                    </Grid>
                    <Grid container item xs={12} xl={8} spacing={6}>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontSize: "0.775rem" }}>
                                Update yout existing email's settings
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Container >
    )

}

export default UpdateCard