// ** React Imports
import React, { Fragment, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import {
  Button,
  Box,
  Grid,
  Dialog,
  IconButton,
  DialogContent,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress
} from '@mui/material'

// ** Icons Imports
import SearchIcon from 'mdi-material-ui/Magnify'

// ** APIs
import { getAllCases } from 'configs/axios/api_helper'

// ** Hooks
import { useAuth } from 'hooks/useAuth'

const SpecificCaseDialog = props => {
  // ** Hooks
  const { user } = useAuth()
  const navigate = useNavigate()

  // ** State
  const [open, setOpen] = useState(false)
  const [caseNumber, setCaseNumber] = useState(null)
  const [loading, setLoading] = useState(false)

  // ** Ref
  const radioGroupRef = useRef(null)

  // ** Functions
  const handleClickListItem = () => {
    setOpen(true)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus()
    }
  }
  const handleCancel = () => {
    setOpen(false)
    handleCloseDialog()
  }

  const onClickOutSide = (event, reason) => {
    if (reason && reason == 'backdropClick') return
    handleCloseDialog()
  }

  const submitSearch = async () => {
    try {
      setLoading(true)
      const { data } = await getAllCases({ id: user?.customerId, zendeskId: caseNumber })
      const [[caseData]] = data
      setLoading(false)
      if (caseData.uid) navigate('/support-case/case/' + caseData?.uid)
      else setMessage
    } catch (error) {
      setLoading(false)
      console.log('----error----', error)
    }
  }

  return (
    <Fragment>
      <Button
        onClick={handleClickListItem}
        variant='text'
        sx={{
          mx: 1,
          fontWeight: 600,
          color: 'common.blue',
          fontSize: '0.825rem',
          whiteSpace: 'nowrap'
        }}
      >
        View a specific case
      </Button>
      <Dialog
        maxWidth='xs'
        fullWidth={true}
        TransitionProps={{ onEntering: handleEntering }}
        open={open}
        onClose={onClickOutSide}
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start'
          }
        }}
      >
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign='start' mb={3}>
              <Typography variant='body1' fontSize={16}>
                View a specific case
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <TextField
                disabled={loading}
                placeholder='Provide a case number'
                label='Provide a case number *'
                size='small'
                variant='outlined'
                fullWidth
                onChange={e => setCaseNumber(e.target.value)}
                InputProps={{
                  style: { borderRadius: 0 },
                  endAdornment: (
                    <InputAdornment position='end'>
                      {loading ? <CircularProgress size={20} /> : null}
                      <IconButton onClick={submitSearch} sx={{ mr: -2 }}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'end' }}>
              <Button autoFocus onClick={handleCancel}>
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default SpecificCaseDialog
